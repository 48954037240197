import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/googlehome"
      }}>{`we`}</a>{` are announcing support for `}<a parentName="p" {...{
        "href": "http://developers.google.com/actions"
      }}>{`Actions on Google`}</a>{` which enables developers to build for the Google Assistant, including Google Home.`}</p>
    <p>{`Google Home is a voice-enabled speaker powered by the `}<a parentName="p" {...{
        "href": "http://developers.google.com/actions"
      }}>{`Google Assistant`}</a>{` which you can ask questions or tell to do things — it’s a pretty amazing device!`}</p>
    <p>{`Now developers will be able to build for the `}<a parentName="p" {...{
        "href": "http://developers.google.com/actions"
      }}>{`Google Assistant`}</a>{`, and with support from `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/googlehome"
      }}>{`Dashbot`}</a>{`, developers can gain insights into usage to increase user engagement, acquisition, and monetization. It’s pretty exciting!`}</p>
    <h2>{`Gain Insights`}</h2>
    <p>{`In addition to traditional metrics like engagement, and retention, we provide bot-specific metrics like conversational analytics, sentiment analysis, and even the full chat transcripts.`}</p>
    <p>{`We added additional support specifically for the Google Assistant, including reporting on Intents, Actions, and Contexts.`}</p>
    <h2>{`Live Transcripts`}</h2>
    <p>{`When viewing transcripts, you can see additional meta data around the conversation — including the Intent, Action, and Context.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152934/0_ke-yzS_BfPFQkINk.png)
    </figure>
Simply hover over to see additional meta data:
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152934/0_0oCdMANjXF9S9lOL.png",
          "alt": null
        }}></img></figure>{`Intents`}</h2>
    <p>{`View the top intents as well as the matching user inputs.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152933/0_tIpZeL18SPUagrne.png",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152931/0_iVqIDrMfSAZ5i6bi.png",
          "alt": null
        }}></img></figure>{`Conversational Flows`}</h2>
    <p>{`With our Message Funnel, you can navigate the flow through your Action to better understand users’ interactions. This is a great way to improve your Action response effectiveness.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152929/0_IugAl-k2C7Bq1RBm.png",
          "alt": null
        }}></img></figure>{`Integration is Easy`}</h2>
    <p>{`We support both Google API.ai and Google Actions SDK integrations.`}</p>
    <p>{`With our `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/sdk/google"
      }}>{`NPM module`}</a>{`, it’s only a couple lines of code — we take care of the rest!`}</p>
    <h3>{`Google API.ai`}</h3>
    <p>{`Include dashbot`}</p>
    <pre><code parentName="pre" {...{}}>{`const dashbot = require(‘dashbot’)(process.env.DASHBOT_API_KEY).google;
`}</code></pre>
    <p>{`Log whenever your webhook is called`}</p>
    <pre><code parentName="pre" {...{}}>{`app.use(bodyParser.json())
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`…
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`app.post(‘/guessnumber’, function(request, response) {
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`<strong>dashbot.logIncoming(request.body);</strong>
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`…
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`});
`}</code></pre>
    <p>{`Finally, whenever you send a message, log the request and outgoing message.`}</p>
    <pre><code parentName="pre" {...{}}>{`let msg = {
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`speech: “I’m thinking of a number from 0 and 100. What’s your first guess?”,
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`data: { google: { expect_user_response: true } },
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`contextOut: [{name:”game”,lifespan:100,parameters:{answer:72}}]
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`}
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`<strong>dashbot.logOutgoing(request.body, msg);</strong>
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`response.send(msg);
`}</code></pre>
    <h3>{`Google Actions SDK`}</h3>
    <p>{`Include dashbot`}</p>
    <pre><code parentName="pre" {...{}}>{`const dashbot = require(‘dashbot’)(process.env.DASHBOT_API_KEY).google;
`}</code></pre>
    <p>{`After creating an Assistant object, pass it to dashbot`}</p>
    <pre><code parentName="pre" {...{}}>{`function helloAction(request, response) {
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`var assistant = new Assistant(request, response);
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`<strong>dashbot.configHandler(assistant);</strong>
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`…
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`}
`}</code></pre>
    <h2>{`Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables brands and developers to increase user engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional metrics like retention and engagement, Dashbot provides bot-specific processing and metrics like conversational analytics, sentiment analysis, and AI response effectiveness. Dashbot also provides tools to take action on this key data, like its Live Person Takeover of chat sessions and Push Notifications for re-engagement.`}</p>
    <p>{`Dashbot natively supports the Google Assistant, Facebook Messenger, Slack, and Kik, and has a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      